<script lang="ts" setup>
import { SubmittedDocuments } from "~/types/SubmittedDocument";

const props = defineProps({
  documents: {
    type: Object as PropType<SubmittedDocuments>,
    required: true,
  },
  align: {
    type: String as PropType<"left" | "right" | "center">,
    default: "center",
  },
});

// // Make a local reactive copy of the prop
// let documents = ref(
//   [...documentsProp].sort(
//     (a, b) => b.lastUpdatedTimestamp - a.lastUpdatedTimestamp
//   )
// );

// Watch for changes on the prop and update the local copy
// watch(props.documents, (newVal) => {
//   props.documents.sort(
//     (a, b) => b.lastUpdatedTimestamp - a.lastUpdatedTimestamp
//   );
// });

const appUserStore = useAppUserData();

const sortingOptions = computed(() => {
  return [
    {
      label: "Last Updated",
      value: "lastUpdatedTimestamp",
    },
    {
      label: "Submitted Date",
      value: "submittedAtTimestamp",
    },
    {
      label: "Creation Date",
      value: "createOnTimestamp",
    },
  ];
});

const sortKey = ref<
  | "lastUpdatedTimestamp"
  | "submittedAtTimestamp"
  | "createOnTimestamp"
  | undefined
>(appUserStore.appUser?.preferredDocumentSortField ?? "submittedAtTimestamp");
const sortDirection = ref<"desc" | "asc">(
  appUserStore.appUser?.preferredDocumentSortOrder ?? "desc"
);

watch(sortKey, async () => {
  appUserStore.appUser!.preferredDocumentSortField = sortKey.value;
  await appUserStore.saveUser();
});

watch(sortDirection, async () => {
  appUserStore.appUser!.preferredDocumentSortOrder = sortDirection.value;
  await appUserStore.saveUser();
});

const sortedDocument = computed(() => {
  const documentsCopy = [...props.documents]; // Create a copy to avoid mutating props

  documentsCopy.sort((a, b) => {
    switch (sortKey.value) {
      case "submittedAtTimestamp":
        if (
          a.submittedAtTimestamp &&
          b.submittedAtTimestamp &&
          a.submittedAtTimestamp.toMillis &&
          b.submittedAtTimestamp.toMillis
        ) {
          return sortDirection.value === "desc"
            ? b.submittedAtTimestamp.toMillis() -
                a.submittedAtTimestamp.toMillis()
            : a.submittedAtTimestamp.toMillis() -
                b.submittedAtTimestamp.toMillis();
        }
      // Fall through if timestamps are not available
      case "createOnTimestamp":
        if (
          a.createOnTimestamp !== undefined &&
          b.createOnTimestamp !== undefined
        ) {
          return sortDirection.value === "desc"
            ? b.createOnTimestamp - a.createOnTimestamp
            : a.createOnTimestamp - b.createOnTimestamp;
        }
      // Fall through if createOnTimestamp is not available
      case "lastUpdatedTimestamp":
      default:
        if (
          a.lastUpdatedTimestamp !== undefined &&
          b.lastUpdatedTimestamp !== undefined
        ) {
          return sortDirection.value === "desc"
            ? b.lastUpdatedTimestamp - a.lastUpdatedTimestamp
            : a.lastUpdatedTimestamp - b.lastUpdatedTimestamp;
        }
        return 0; // If none of the timestamps are available, consider them equal
    }
  });

  return documentsCopy; // Return the sorted copy
});

const toggleDirection = () => {
  sortDirection.value = sortDirection.value === "desc" ? "asc" : "desc";
};
</script>

<template>
  <div class="overflow-hidden flex flex-col pb-8 px-2 w-full">
    <div class="py-4 flex flex-row max-w-[800px] w-full mx-auto">
      <div class="w-full mr-2">
        <BaseSelect
          v-model="sortKey"
          :options="sortingOptions"
          :can-clear="false"
        />
      </div>
      <BaseIconButton
        class="!h-[50px] w-[50px] text-[24px]"
        @click="toggleDirection"
      >
        <icon v-if="sortDirection == 'desc'" name="iconoir:sort-down" />
        <icon v-if="sortDirection == 'asc'" name="iconoir:sort-up" />
      </BaseIconButton>
    </div>
    <DocumentsItem
      v-for="document in sortedDocument"
      :key="`${document.id}-${document.state}`"
      :submitted-document="document"
      class="mb-4 rounded shadow max-w-[800px] w-full"
      :style="{
        marginLeft: align === 'right' || align == 'center' ? 'auto' : '0',
        marginRight: align === 'left' || align == 'center' ? 'auto' : '0',
      }"
    />
  </div>
</template>

<style scoped></style>
